<template>
  <div class="home">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Novo local</span>
    </button>

    <button
      style="margin-left: 10px"
      class="button field is-warning"
      @click="importFileDrivers()"
    >
      <b-icon icon="upload"></b-icon>
      <span>Importar valor dos parceiros</span>
    </button>

    <button
      style="margin-left: 10px"
      class="button field is-warning"
      @click="importFileCustomers()"
    >
      <b-icon icon="upload"></b-icon>
      <span>Importar valor dos clientes</span>
    </button>

    <input
      id="input-file-driver"
      style="display: none"
      type="file"
      @change="onChangeDriver"
    />

    <input
      id="input-file-customer"
      style="display: none"
      type="file"
      @change="onChangeCustomer"
    />

    <b-table
      :data="allLocations"
      ref="table"
      paginated
      per-page="10"
      :striped="true"
      :debounce-search="1000"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">{{
        props.row.id
      }}</b-table-column>

      <b-table-column
        field="name"
        label="Nome"
        sortable
        :searchable="true"
        v-slot="props"
        >{{ props.row.name.toUpperCase() }}</b-table-column
      >

      <!-- <b-table-column
        field="value"
        label="Valor adicional"
        sortable
        :searchable="true"
        v-slot="props"
        >R${{
          props.row.value
            ? parseFloat(props.row.value).toFixed(2).replace('.', ',')
            : '0,00'
        }}</b-table-column
      > -->

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>
    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Nome">
              <b-input required v-model="model.name"></b-input>
            </b-field>
            <b-field label="Clientes (Tabela de preços)">
              <button
                class="button field is-info is-light"
                @click="addCustomer"
              >
                <b-icon icon="plus"></b-icon>
                <span>Adicionar cliente</span>
              </button>
            </b-field>
            <b-field v-for="(customer, index) of customers" :key="index">
              <b-collapse
                class="card"
                animation="slide"
                :open="isOpen == index"
                @open="isOpen = index"
              >
                <div
                  slot="trigger"
                  slot-scope="props"
                  class="card-header"
                  role="button"
                >
                  <p class="card-header-title">
                    {{
                      getCustomerByID(customers[index].id)
                        ? getCustomerByID(
                            customers[index].id
                          ).name.toUpperCase()
                        : 'Nenhum cliente selecionado'
                    }}
                  </p>
                  <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                </div>
                <div class="card-content">
                  <div class="content">
                    <b-field label="Cliente">
                      <model-list-select
                        :list="allFilterCustomers"
                        :custom-text="customerCustomOption"
                        placeholder="Selecione um cliente"
                        required
                        v-model="customers[index].id"
                        option-value="id"
                      >
                      </model-list-select>
                    </b-field>
                    <b-field label="Valor *">
                      <b-input
                        required
                        v-money="moneyFormat"
                        v-model="customers[index].value"
                      ></b-input>
                    </b-field>
                  </div>
                </div>
                <footer class="card-footer">
                  <a @click="removeCustomer(index)" class="card-footer-item"
                    >Remover</a
                  >
                </footer>
              </b-collapse>
            </b-field>

            <b-field label="Valor parceiros *">
              <b-input
                required
                v-money="moneyFormat"
                v-model="model.value_driver"
              ></b-input>
            </b-field>

            <b-button type="button field is-info" @click="save" class="mt-10"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/locations/store/service'
import Location from '../models/location'
import XLSX from 'xlsx'

export default {
  name: 'Locations',
  data() {
    return {
      model: Location.model,
      isCardModalActive: false,
      customers: [],
      drivers: [],
      allFilterCustomers: [],
      allFilterDrivers: [],
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('locations', ['allLocations']),
    ...mapState('customers', ['allCustomers']),
    ...mapState('drivers', ['allDrivers']),
  },
  methods: {
    ...mapActions('locations', ['getAllLocations']),
    ...mapActions('customers', ['getAllCustomers']),
    ...mapActions('drivers', ['getAllDrivers']),
    async init() {
      await this.getAllLocations()
      await this.getAllCustomers()
      await this.getAllDrivers()

      this.allFilterCustomers = this.allCustomers.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })

      this.allFilterDrivers = this.allDrivers.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })

      this.allFilterDrivers = this.allFilterDrivers.filter(
        (x) => x.driver_type === 'Parceiro'
      )
    },
    onChangeDriver(event) {
      const files = event.target.files,
        f = files[0]
      const self = this.saveImportDriver
      const reader = new FileReader()
      reader.onload = function (e) {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        /* DO SOMETHING WITH workbook HERE */
        console.log(workbook)
        const worksheet = workbook.Sheets[sheetName]
        self(XLSX.utils.sheet_to_json(worksheet))
      }
      reader.readAsArrayBuffer(f)
    },
    onChangeCustomer(event) {
      const files = event.target.files,
        f = files[0]
      const self = this.saveImportCustomer
      const reader = new FileReader()
      reader.onload = function (e) {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        /* DO SOMETHING WITH workbook HERE */
        console.log(workbook)
        const worksheet = workbook.Sheets[sheetName]
        self(XLSX.utils.sheet_to_json(worksheet))
      }
      reader.readAsArrayBuffer(f)
    },
    async save() {
      const locationSave = { ...this.model }

      console.log('location ', locationSave)

      locationSave.value_driver = locationSave.value_driver.replace('R$ ', '')
      locationSave.value_driver = locationSave.value_driver.replace(',', '')

      locationSave.customers = this.customers.map((x) => {
        x.value = x.value.replace('R$ ', '')
        x.value = x.value.replace(',', '')
        return {
          ...x,
        }
      })

      locationSave.drivers = this.drivers.map((x) => {
        x.value = x.value.replace('R$ ', '')
        x.value = x.value.replace(',', '')
        return {
          ...x,
        }
      })

      console.log('model ', locationSave)

      try {
        const save = locationSave.id
          ? await service.updateLocation(locationSave)
          : await service.saveLocation(locationSave)

        await this.$success('Local')
        await location.reload(true)

        this.model = Location.model
      } catch (error) {
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    customerCustomOption(item) {
      return `
        ${item.name.toUpperCase()}
          `
    },
    driverCustomOption(item) {
      return `
        ${item.nickname.toUpperCase()} (${item.name.toUpperCase()} ${item.last_name.toUpperCase()})
          `
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover o local?')

        if (result.isConfirmed) {
          await service.deleteLocation(id)

          await this.$delete('Local')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    removeCustomer(index) {
      this.customers.splice(index, 1)
    },
    removeDriver(index) {
      this.drivers.splice(index, 1)
    },
    getCustomerByID(id) {
      return this.allCustomers.find((x) => x.id === id)
    },
    getDriverByID(id) {
      return this.allDrivers.find((x) => x.id === id)
    },
    addCustomer() {
      this.customers.push({
        id: null,
        value: null,
      })
    },
    addDriver() {
      this.drivers.push({
        id: null,
        value: null,
      })
    },
    onEdit(item) {
      this.model = { ...item }
      console.log(this.model)
      this.customers = this.model.customers.map((x) => {
        return { id: x.id, value: parseFloat(x.pivot.value).toFixed(2) }
      })

      this.drivers = this.model.drivers.map((x) => {
        return { id: x.id, value: parseFloat(x.pivot.value).toFixed(2) }
      })
      //   this.model.value = parseFloat(this.model.value).toFixed(2)
    },
    importFileDrivers() {
      document.getElementById('input-file-driver').click()
    },
    importFileCustomers() {
      document.getElementById('input-file-customer').click()
    },
    async saveImportDriver(list) {
      try {
        const listTemp = list
          ? list.map((x) => {
              console.log('info ', x)
              return {
                city: x['CIDADE'].toString(),
                value: x['VALOR'].toString(),
              }
            })
          : []

        await service.saveImportDriverData({ data: listTemp })

        await this.$success('Importação')
        this.init()
      } catch (error) {
        console.log('error ', error)
        this.$error(
          'Falha ao importar planilha, verifique os dados cadastrados'
        )
      }
    },
    async saveImportCustomer(list) {
      try {
        const listTemp = list
          ? list.map((x) => {
              console.log('info ', x)
              return {
                city: x['CIDADE'].toString().toUpperCase(),
                customer: x['CLIENTE'].toString().toUpperCase(),
                value: x['VALOR'].toString(),
              }
            })
          : []

        const result = listTemp.reduce(function (r, a) {
          r[a.city] = r[a.city] || []
          r[a.city].push(a)
          return r
        }, Object.create(null))

        console.log(result)

        await service.saveImportCustomerData({ data: result })

        await this.$success('Importação')
        this.init()
      } catch (error) {
        console.log('error ', error)
        this.$error(
          'Falha ao importar planilha, verifique os dados cadastrados'
        )
      }
    },
  },
}
</script>

<style scoped></style>